/* eslint-disable no-undef */
import { Controller } from "@hotwired/stimulus"

class ThemeSelect extends Controller {
  static targets = ["choice", "button", "selectedLabel"]
  static outlets = ["dialog"]
  static classSelected = "-selected"
  static classHidden = "-hidden"
  static themeLabels = {
    light: gettext("clair"),
    dark: gettext("sombre"),
    auto: gettext("automatique"),
  }

  initialize() {
    if (typeof HTMLDialogElement === "function") {
      this.selected = this.element.dataset.selected
      this.showSelection()
    } else {
      this.element.classList.add(ThemeSelect.classHidden)
    }
  }

  showModal() {
    this.dialogOutlet.showModal()
    this.previousSelected = this.selected
  }

  cancel() {
    this.selected = this.previousSelected
    this.changeTheme()
    this.closeDialog()
  }

  closeDialog() {
    this.dialogOutlet.close()
  }

  setSelected(event) {
    this.selected = event.currentTarget.dataset.key
    this.changeTheme()
  }

  changeTheme() {
    document.documentElement.setAttribute("class", `ctx-${this.selected}-mode`)
    this.showSelection()
  }

  showSelection() {
    this.choiceTargets.forEach((element) => {
      const checked = element.dataset.key === this.selected
      const inputElement = element.querySelector("input")
      if (inputElement) {
        inputElement.checked = checked
      }
      if (checked) {
        element.classList.add(ThemeSelect.classSelected)
      } else {
        element.classList.remove(ThemeSelect.classSelected)
      }
    })
    this.selectedLabelTarget.textContent = ThemeSelect.themeLabels[this.selected]
  }
}

export { ThemeSelect }
