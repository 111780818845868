import { Controller } from "@hotwired/stimulus"

class PaywallBase extends Controller {
  static targets = ["wall", "container", "banner"]
  static outlets = ["footer"]

  footerParent = undefined

  appendFooter() {
    const footer = this.footerOutletElement
    this.footerParent = footer.parentElement
    this.wallTarget.appendChild(footer)
    // todo what is this `-locked` modifier?
    footer.classList.remove("-locked")
  }

  detachFooter() {
    this.footerParent.appendChild(this.footerOutletElement)
  }

  connect() {
    // Defer the execution of appendFooter until after the current call stack has cleared.
    // This allow time for other parts of the page to initialize
    setTimeout(() => {
      this.appendFooter()
    }, 100)
  }
}

export { PaywallBase }
