import { Controller } from "@hotwired/stimulus"

class Clipboard extends Controller {
  static targets = ["icon", "source"]
  static classHidden = "-hidden"

  _getText(sourceMethod) {
    if (sourceMethod === "value") {
      return this.sourceTarget.value
    } else if (sourceMethod === "dataset") {
      return this.sourceTarget.dataset.clipboardDataText
    } else {
      console.error("Could not get text to copy to clipboard")
      return ""
    }
  }

  _animate() {
    const hiddenIcon = this.iconTargets.find(icon => icon.classList.contains(Clipboard.classHidden))
    const visibleIcon = this.iconTargets.find(icon => !icon.classList.contains(Clipboard.classHidden))

    hiddenIcon.classList.remove(Clipboard.classHidden)
    visibleIcon.classList.add(Clipboard.classHidden)
    setTimeout(() => {
      hiddenIcon.classList.add(Clipboard.classHidden)
      visibleIcon.classList.remove(Clipboard.classHidden)
    }, 3000)
  }

  async copy({ params: { sourceMethod, confirmation } }) {
    const text = this._getText(sourceMethod)
    if (text === "") {
      alert("Impossible de copier le texte dans le presse-papier")
      return
    }
    try {
      await navigator.clipboard.write([new ClipboardItem({
        "text/plain": new Blob([text], { type: "text/plain" }),
        "text/html": new Blob([text], { type: "text/html" }),
      })])
      if (confirmation === "visual") {
        this._animate()
      } else {
        alert("La classe a bien été copiée dans le presse-papier.")
      }
    } catch (err) {
      window.prompt("Copier dans le presse-papier : Cmd + C, Entrée", text)
    }
  }
}

export { Clipboard }
