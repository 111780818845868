import { Controller } from "@hotwired/stimulus"

class openDialog extends Controller {
  static outlets = ["dialog"]

  open() {
    this.dialogOutlet.showModal()
  }

  close() {
    this.dialogOutlet.close()
  }
}

export { openDialog }
