import { log } from "../utils/logging"
import { loadScript } from "../utils/scripts"

// Whitelist of custom dimensions (paramName → dimensionId)
const CUSTOM_DIMENSIONS = {
  pageType: 1,
  userSectors: 2,
  userType: 3,
  objectId: 4,
  pageGeo: 5,
}

export class Matomo {
  isEnabled = false
  isTagManagerInit = false

  // --- Public methods

  init(enabled, trackerUrl, siteId) {
    this.isEnabled = enabled && trackerUrl && siteId
    if (!this.isEnabled || this.isInit()) {
      return
    }

    // Matomo settings https://developer.matomo.org/guides/tracking-javascript-guide
    window._paq = window._paq || []
    window._paq.push(["setTrackerUrl", trackerUrl + "/matomo.php"])
    window._paq.push(["setSiteId", siteId])
    // Enable download & outlink tracking
    window._paq.push(["enableLinkTracking"])
    // Accurately measure the time spent in the visit
    window._paq.push(["enableHeartBeatTimer"])

    window.matomoAsyncInit = () => {
      log("Matomo init OK")
    }

    // Load script
    loadScript("//cdn.matomo.cloud/contexte.matomo.cloud/matomo.js")
      .catch(() => console.error("Could not load Matomo"))

    return true
  }

  isInit() {
    return !!window._paq
  }

  initTagManager(containerId) {
    if (!containerId || this.isTagManagerInit) {
      return
    }

    // Matomo Tag Manager doc https://matomo.org/faq/tag-manager/data-layer-in-matomo-tag-manager/
    const _mtm = window._mtm = window._mtm || []
    _mtm.push({
      "mtm.startTime": new Date().getTime(),
      event: "mtm.Start",
    })

    // Load script
    loadScript(
      "//cdn.matomo.cloud/contexte.matomo.cloud/" +
        containerId +
        ".js?v=" +
        Date.now(),
    )
      .then(() => {
        this.isTagManagerInit = true
        log("Matomo Tag Manager init OK")
      })
      .catch(() => console.error("Could not load Matomo"))

    return true
  }

  // Send Pageview to Matomo
  trackPageView(params) {
    if (!this.isEnabled) {
      return
    }
    log("Matomo trackPageView")
    this.trackDimensions(params)
    window._paq.push(["trackPageView"])
  }

  // Send SiteSearch to Matomo
  trackSiteSearch(params) {
    if (!this.isEnabled) {
      return
    }
    const { searchQuery, searchCount } = params
    log("Matomo trackSiteSearch", searchQuery, searchCount)
    this.trackDimensions(params)
    window._paq.push(["trackSiteSearch", searchQuery, null, searchCount])
  }

  // Send Event to Matomo
  trackEvent(eventCategory, eventName, params) {
    if (!this.isEnabled) {
      return
    }
    log("Matomo trackEvent", eventCategory, eventName)
    this.trackDimensions(params)
    const objectName = params.objectName ? params.objectName : undefined
    window._paq.push(["trackEvent", eventCategory, eventName, objectName])
  }

  // --- Internal methods

  trackDimensions(params) {
    // Track Custom Dimensions
    for (const [paramName, paramValue] of Object.entries(params)) {
      const dimensionId = [CUSTOM_DIMENSIONS[paramName]]
      if (dimensionId && paramValue) {
        window._paq.push(["setCustomDimension", dimensionId, paramValue])
        window._mtm.push({ [`co.${paramName}`]: paramValue })
      }
    }
    // Track User Id
    if (params.userId) {
      window._paq.push(["setUserId", params.userId])
      window._mtm.push({ "co.userId": params.userId })
    }
    // Track Page Title
    if (params.pageTitle) {
      window._paq.push(["setDocumentTitle", params.pageTitle])
      window._mtm.push({ "co.pageTitle": params.pageTitle })
    }
  }
}
