import { Controller } from "@hotwired/stimulus"
import { log } from "../utils/logging"
import { loadScript } from "../utils/scripts"

const REQUIRED_TRACKING_CHOICES = ["hotjar", "Linkedin", "Matomo", "pardot", "PostHog"]

class CookiesConsent extends Controller {
  static targets = ["env"]

  connect() {
    const isDebug = false

    if (!isDebug) {
      this.init()
    } else {
      setTimeout(() => {
        this.dispatch("complete", {
          detail: REQUIRED_TRACKING_CHOICES.reduce((memo, service) => {
            memo[service] = true
            return memo
          }, {}),
        })
      }, 500)
    }
  }

  init() {
    const env = this.getEnv()
    if (!env.axeptioId) {
      return
    }

    // Axeptio settings https://developers.axeptio.eu/v/francais/sdk/integration-du-sdk
    window.axeptioSettings = {
      clientId: env.axeptioId,
      userCookiesDomain: location.hostname.split(".").slice(-2).join("."),
      cookiesVersion: "contexte-base",
      jsonCookieName: "axeptio_cookies",
      authorizedVendorsCookieName: "axeptio_authorized_vendors",
      tokenInstance: env.userUuid || undefined,
    }

    // Load script
    loadScript("//static.axept.io/sdk.js")
      .then(
        () => {
          window._axcb = window._axcb || []
          window._axcb.push((axeptio) => {
            const existingChoices = axeptio.userPreferencesManager.choices
            if (this.shouldOpenCookies(existingChoices)) {
              log("Missing required choices", existingChoices)
              this.openCookies()
            }

            axeptio.on("cookies:complete", (choices) => {
              log("Axeptio complete", choices)
              this.dispatch("complete", { detail: choices })
            })
          })
        },
      ).catch(
        () => console.error("Could not load Axeptio"),
      )
  }

  // If the user has made choices, but has not made a choice for a required service
  // then we should open the Axeptio modal
  shouldOpenCookies(choices) {
    const hasChoices = Object.keys(choices).length
    const hasMissingRequiredChoice = REQUIRED_TRACKING_CHOICES.find((service) => {
      // The service key will be missing if the user has not made a choice
      return !(service in choices)
    })
    return hasChoices && hasMissingRequiredChoice
  }

  openCookies() {
    window.openAxeptioCookies({ currentStepIndex: 1 })
  }

  // --- Internal methods

  getEnv() {
    return (this.envTarget && this.envTarget.dataset) || {}
  }
}

export { CookiesConsent }
