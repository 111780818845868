import { Controller } from "@hotwired/stimulus"

class UIKitButtons extends Controller {
  static targets = [
    "modifier",
    "modifierValue",
    "inspector",
    "inspectorCode",
    "inspectorButton",
    "inspectorProp",
  ]

  static classBase = "button"
  static classSelected = "-selected"
  static classHidden = "-hidden"
  static classSquare = "-square"
  static classRounded = "-rounded"

  initialize() {
    this.state = {
      skin: "default",
      level: "primary",
      contrasted: "none",
      inverted: "none",
      size: "m",
    }
    this.hasIcon = "False"
    this.hasText = "True"
    this.hasRounded = "False"
    this.updateAll()
  }

  update(event) {
    const element = event.currentTarget
    const key = element.parentNode.dataset.key
    const value = element.dataset.value
    this.state[key] = value
    this.removeSelected(element.parentNode)
    this.updateAll()
  }

  removeSelected(element) {
    Array.from(element.children).forEach(child => {
      child.classList.remove(UIKitButtons.classSelected)
    })
  }

  updateAll() {
    this.hideIfUnhandled()
    this.modifierTargets.forEach(element => {
      this.setBackground(element)
    })
    this.modifierValueTargets.forEach(element => {
      element.className = UIKitButtons.classBase + " "
      let prefix = "-"
      if (element.parentNode.dataset.key === "size") {
        prefix = "-size-"
      }
      if (!["default", "none", undefined].includes(element.dataset.value)) {
        element.classList.add(prefix + element.dataset.value)
      }
      this.applySquareAndRounded(element)
      if (
        this.state[element.parentNode.dataset.key] === element.dataset.value &&
        element.parentNode.dataset.key !== "icon-text") {
        element.classList.add(UIKitButtons.classSelected)
      }
      Object.keys(this.state).forEach(key => {
        const value = this.state[key]
        if (value && key !== "size" && !["default", "none"].includes(value) && element.parentNode.dataset.key !== key) {
          element.classList.add("-" + value)
        }
      })
    })
    this.updateInspectors()
  }

  applySquareAndRounded(element) {
    if (
      element.dataset.hasIcon === "True" &&
      element.dataset.hasText === "False") {
      element.classList.add(UIKitButtons.classSquare)
    }
    if (
      element.dataset.hasRounded === "True") {
      element.classList.add(UIKitButtons.classRounded)
    }
  }

  updateHasIconTextRounded(event) {
    this.hasIcon = event.currentTarget.dataset.hasIcon
    this.hasText = event.currentTarget.dataset.hasText
    this.hasRounded = event.currentTarget.dataset.hasRounded
    this.updateInspectors()
  }

  showInspector() {
    this.inspectorTargets.forEach(element => {
      if (element.dataset.hasIcon === this.hasIcon &&
        element.dataset.hasText === this.hasText &&
        element.dataset.hasRounded === this.hasRounded) {
        element.classList.remove(UIKitButtons.classHidden)
      } else {
        element.classList.add(UIKitButtons.classHidden)
      }
    })
  }

  updateInspectors() {
    let className = UIKitButtons.classBase
    let prefix = " "
    Object.keys(this.state).forEach(key => {
      let value = this.state[key]
      if (key === "size") {
        value = "size-" + value
      }
      if (!["default", "none"].includes(value)) {
        className += prefix + "-" + value
        prefix = " "
      }
    })
    this.setBackground(this.inspectorButtonTarget.parentNode)
    this.inspectorButtonTargets.forEach(element => {
      element.className = className
      this.applySquareAndRounded(element)
    })
    this.inspectorCodeTargets.forEach(element => {
      const dataset = element.parentNode.parentNode.dataset
      let buttonclassName = className
      if (
        dataset.hasIcon === "True" &&
        dataset.hasText === "False") {
        buttonclassName += ` ${UIKitButtons.classSquare}`
      }
      if (
        dataset.hasRounded === "True") {
        buttonclassName += ` ${UIKitButtons.classRounded}`
      }
      let textContent = `<button class="${buttonclassName}">`
      if (dataset.hasIcon === "True") {
        textContent += `
  <svg aria-hidden="true">
    <use href="{% static 'ui/kit/images/icon.svg' %}#root" />
  </svg>`
      }
      if (dataset.hasText === "True") {
        textContent += `
  Buttons`
      }
      textContent += `
</button>`
      element.textContent = textContent
    })
    this.inspectorPropTargets.forEach(element => {
      const key = element.dataset.key
      let value = this.state[key]
      if (key === "size") {
        value = value.toUpperCase()
      }
      element.textContent = value
    })
    this.showInspector()
  }

  setBackground(element) {
    ["inverted", "contrasted"].forEach(modifier => {
      const className = "-" + modifier
      if (this.state[modifier] === modifier) {
        element.classList.add(className)
      } else {
        element.classList.remove(className)
      }
    })
  }

  hideIfUnhandled() {
    this.modifierTargets.forEach(element => {
      if (element.dataset.key === "inverted" && this.state.skin !== "default") {
        element.parentNode.classList.add(UIKitButtons.classHidden)
        this.state.inverted = "none"
      } else {
        element.parentNode.classList.remove(UIKitButtons.classHidden)
      }
    })
  }
}

export { UIKitButtons }
