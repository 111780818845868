import { Controller } from "@hotwired/stimulus"

const HIDDEN_CLASS = "-hidden"
const WORDS_UPPER_LIMIT = 70
const WORDS_LOWER_LIMIT = 60

class ReadMore extends Controller {
  static targets = ["button", "body"]

  connect() {
    if (this.shouldTruncate()) {
      this.truncate()
    }
  }

  shouldTruncate() {
    return this.bodyTarget.innerHTML.split(" ").length > WORDS_UPPER_LIMIT
  }

  truncate() {
    this.initialBody = this.bodyTarget.innerHTML
    this.bodyTarget.innerHTML = this.initialBody.split(" ").slice(0, WORDS_LOWER_LIMIT).join(" ") + "…"
    this.buttonTarget.classList.remove(HIDDEN_CLASS)
  }

  expand(event) {
    if (this.initialBody && event.target.tagName !== "A") {
      this.bodyTarget.innerHTML = this.initialBody
      this.buttonTarget.classList.add(HIDDEN_CLASS)
      delete this.initialBody
    }
  }
}

export { ReadMore }
