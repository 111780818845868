import { PaywallBase } from "../base/paywall.js"

class PaywallLists extends PaywallBase {
  static targets = [...PaywallBase.targets, "main", "toggleEnabledButton", "trialBlock"]
  static values = {
    threshold: { type: Number, default: 500 },
  }

  enabled = true
  active = false
  pageScrollTop = 0

  connect() {
    super.connect()
    this._deactivate()
    document.body.style.setProperty("--js-paywall-lists-threshold", `${this.thresholdValue}px`)

    const observer = new IntersectionObserver((entries, observer) => {
      if (!this.active) {
        return
      }
      entries.forEach((entry) => {
        if (entry.intersectionRatio < 1) {
          this._deactivate()
        }
      })
    }, { rootMargin: "50px 0px -50px 0px" },
    )
    observer.observe(this.containerTarget)
  }

  _activate(offset) {
    this.active = true

    let containerMarginTop = 0
    if (offset) {
      containerMarginTop = document.scrollingElement.scrollTop + offset
    } else {
      containerMarginTop = this.wallTarget.offsetTop + this.thresholdValue
    }
    this.containerTarget.style.marginTop = `${containerMarginTop}px`
    this.wallTarget.classList.add("-active")

    // Limit main element height to match the paywall height
    const mainMargin = parseInt(getComputedStyle(this.mainTarget).marginTop)
    const paywallRect = this.wallTarget.getBoundingClientRect()
    const totalHeight = paywallRect.height - mainMargin + "px"
    this.mainTarget.style.maxHeight = totalHeight
    this.element.style.minHeight = totalHeight
    this.wallTarget.style.top = ""
  }

  _deactivate() {
    this.active = false
    this.wallTarget.classList.remove("-active")
    this.wallTarget.style.top = `calc(100vh - ${this.bannerTarget.offsetHeight}px)`
    this.containerTarget.style.marginTop = ""

    // Reset main element height
    this.mainTarget.style.maxHeight = null
    this.element.style.minHeight = null
  }

  _disable() {
    this._deactivate()
    this.enabled = false
    this.wallTarget.classList.add("-disabled")
    this.detachFooter()
  }

  _reenable() {
    this.enabled = true
    this.wallTarget.classList.remove("-disabled")
    this.appendFooter()
    this._activate(window.visualViewport.height - this.bannerTarget.offsetHeight)
    document.scrollingElement.scrollBy({ left: 0, top: this.trialBlockTarget.offsetHeight + this.bannerTarget.offsetHeight, behavior: "smooth" })
  }

  toggleEnabled() {
    if (this.enabled) {
      this._disable()
    } else {
      this._reenable()
    }
    this.toggleEnabledButtonTarget.classList.toggle("-reversed")
  }

  handlePageScroll() {
    const deltaY = window.scrollY - this.pageScrollTop

    if (this.enabled) {
      if (!this.active) {
        if (deltaY > 0 && window.scrollY > this.thresholdValue) {
          this._activate()
        }
      }
    }

    this.pageScrollTop = window.scrollY
  }
}

export { PaywallLists }
