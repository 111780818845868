export function loadScript(src) {
  if (document.querySelector("script[src='" + src + "']")) {
    return Promise.reject(new Error("Script already loaded: " + src))
  }

  return new Promise((resolve, reject) => {
    const script = document.createElement("script")
    script.type = "text/javascript"
    script.onload = resolve
    script.onerror = reject
    script.src = src
    document.head.append(script)
  })
}
