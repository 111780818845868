import { Controller } from "@hotwired/stimulus"

class Facet extends Controller {
  static targets = ["input", "item"]

  internalSearch() {
    const search = this.inputTarget.value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")

    this.itemTargets.forEach((elt) => {
      const value = elt.querySelector(".facet__name").textContent.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      if (!value.includes(search)) {
        elt.style.display = "none"
      } else {
        elt.style.display = null
      }
    })
  }

  stopPropagation(event) {
    // Stop propagation to ExpandableHeader#closeOrOpen
    // to avoid opening the header when scrolling inside facets.
    event.stopPropagation()
  }

  noop() {}
}

export { Facet }
