const CO_DEBUG_LOGGING = "co_debug_logging"

function isEnabled() {
  return !!localStorage[CO_DEBUG_LOGGING]
}

export function log() {
  try {
    if (isEnabled()) {
      console.log(...arguments)
    }
  } catch {
    // Nothing
  }
}

// Expose logging helper
window.toggleDebugLogging = function() {
  if (isEnabled()) {
    delete localStorage[CO_DEBUG_LOGGING]
    console.log("Debug logging disabled")
  } else {
    localStorage[CO_DEBUG_LOGGING] = true
    console.log("Debug logging enabled")
  }
}
