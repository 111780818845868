import { Controller } from "@hotwired/stimulus"

const SETTINGS_BANNER_CLOSED = "co_banner_closed"

class Banner extends Controller {
  static classHidden = "-hidden"
  static values = {
    bannerId: Number,
  }

  show() {
    this.element.classList.remove(Banner.classHidden)
  }

  hide() {
    this.element.classList.add(Banner.classHidden)
  }

  handleClickClose = (ev) => {
    localStorage.setItem(this.getStorageKey(), true)

    this.hide()
  }

  getStorageKey() {
    if (this.bannerIdValue) {
      return SETTINGS_BANNER_CLOSED + "_" + this.bannerIdValue
    }
    return SETTINGS_BANNER_CLOSED
  }

  connect() {
    if (!this.hasBannerIdValue) return
    const isBannerClosed = localStorage.getItem(this.getStorageKey())
    if (isBannerClosed) {
      this.hide()
    } else {
      this.show()
    }
  }
}

export { Banner }
