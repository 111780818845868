import { Controller } from "@hotwired/stimulus"

const VARIANT_CLASS = "experiment__variant"

export class Experiment extends Controller {
  static values = {
    key: String,
  }

  connect() {
    this.desiredDisplayType = this.element.dataset.experimentDisplayType || "flex"
    this.handleVariant()
  }

  // --- Private methods

  // Return the indicated variant key in the url
  getDebugVariant() {
    const currentUrl = new URL(window.location.href)
    const params = new URLSearchParams(currentUrl.search)

    return params.get(`debug-${this.keyValue}`)
  }

  // Send to posthog the user feature flag and display the posthog variant or the desired variant (Note: the second case only affects the code locally and does not change anything on the server side)
  handleVariant() {
    const debugVariantKey = this.getDebugVariant()
    if (debugVariantKey) {
      this.displayVariant(debugVariantKey)
    } else {
      this.fetchPosthogVariant(this.keyValue).then((variantKey) => {
        this.displayVariant(variantKey, true)
      })
    }
  }

  // Change the css in order to display the correct variant
  displayVariant(variantKey, sendEvent = false) {
    if (sendEvent) {
      window.tracking.trackEvent("Experiment", "$feature_flag_called", {
        $feature_flag: this.keyValue,
        $feature_flag_response: variantKey,
      })
    }

    const children = this.element.querySelectorAll(`.${VARIANT_CLASS}`)
    let hasVariant = false

    children.forEach((child) => {
      if (child.dataset.variantKey === variantKey) {
        child.style.display = this.desiredDisplayType
        hasVariant = true
      } else {
        child.style.display = "none"
      }
    })

    if (!hasVariant) {
      const controlDom = this.element.querySelector(
        `.${VARIANT_CLASS}[data-variant-key='control']`,
      )
      controlDom.style.display = this.desiredDisplayType
    }
  }

  // Retrieve the user group test according to the experiment name
  async fetchPosthogVariant(experimentKey) {
    return new Promise((resolve) => {
      window.posthog.onFeatureFlags(async (flags, flagVariants) => {
        resolve(flagVariants[experimentKey])
      })
    })
  }
}
