import { Controller } from "@hotwired/stimulus"

class Searchfacet extends Controller {
  static targets = ["input", "item"]

  internalSearch() {
    const search = this.inputTarget.value
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")

    this.itemTargets.forEach((elt) => {
      const value = elt
        .querySelector(".searchfacet__name")
        .textContent.toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")

      elt.classList.toggle("-hidden", !value.includes(search))
    })
  }

  clearFilters() {
    if (this.element.querySelector("input:checked")) {
      this.element.querySelectorAll("input").forEach(el => el.removeAttribute("checked"))
      this.dispatch("filterCleared")
    }
  }

  noop() {}
}

export { Searchfacet }
