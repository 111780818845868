import { Controller } from "@hotwired/stimulus"
import intlTelInput from "intl-tel-input"

class PhoneField extends Controller {
  connect() {
    if ("intlTelInputId" in this.element.dataset) {
      // intl-tel-input disconnects then reconnects the input from the DOM
      // this should run only once per input
      return
    }
    intlTelInput(this.element, {
      hiddenInput: this.element.getAttribute("name").replace("-second", ""),
      initialCountry: "FR",
      preferredCountries: ["FR", "BE"],
      separateDialCode: true,
      utilsScript: "/static/ui/components/phonefield/intl-tel-input-utils/main.js",
    })
  }
}

export { PhoneField }
