import { Controller } from "@hotwired/stimulus"
import { isMobile } from "../../utils/breakpoints.js"

class Header extends Controller {
  static targets = ["iconBurger", "iconClose", "logo", "button", "svgElementButton", "bellHighlight"]
  static outlets = ["menu"]
  static classHidden = "-hidden"
  static values = {
    isHuge: Boolean,
  }

  connect() {
    this.currentScrollPercent = 0
    if (this.isHugeValue) {
      this.mobileBreakpoint = getComputedStyle(document.documentElement).getPropertyValue("--breakpoint-mobile")
      this.endHeaderHeight = 48
      this.endSvgHeight = 24
      this.endLogoHeight = 40
      this.endButtonHeight = 40
      this.checkScreenWidth()
      window.addEventListener("resize", () => {
        this.checkScreenWidth()
      })
      window.addEventListener("scroll", () => {
        this.onScrollHandle()
      })
    }
  }

  openMenu(event) {
    event.preventDefault()

    if (this.hasMenuOutlet) {
      if (this.menuOutlet.isOpen()) {
        this.menuOutlet.close()
      } else {
        this.menuOutlet.open(this.currentScrollPercent)
      }
    }
  }

  // Called by NavMenu#refreshDependencies
  refreshIconStatus() {
    if (this.hasmenuOutlet && this.menuOutlet.isOpen()) {
      this.iconCloseTarget.classList.remove(Header.classHidden)
      this.iconBurgerTarget.classList.add(Header.classHidden)
    } else {
      this.iconBurgerTarget.classList.remove(Header.classHidden)
      this.iconCloseTarget.classList.add(Header.classHidden)
    }
  }

  // Increase and decrease the header height, logo height and buttons size according to the scroll
  onScrollHandle() {
    const scrollPercent = Math.max(0, Math.min(window.scrollY / (this.headerHeightXL - this.endHeaderHeight), 1))
    this.currentScrollPercent = scrollPercent

    // Ajust the header height
    this.element.style.height = this.headerHeightXL - scrollPercent * (this.headerHeightXL - this.endHeaderHeight) + "px"

    // Ajust the logo height
    const logoCurrentHeight = this.endLogoHeight - scrollPercent * (this.endLogoHeight - this.logoHeight)
    this.logoTarget.setAttribute("viewBox", `0 0 254 ${logoCurrentHeight}`)
    this.logoTarget.setAttribute("height", `${logoCurrentHeight}`)

    // Adjust bell highlight position
    if (this.hasBellHighlightTarget) {
      if (scrollPercent === 1) {
        this.bellHighlightTarget.style.top = "1rem"
        this.bellHighlightTarget.style.left = "2rem"
      } else {
        this.bellHighlightTarget.style.top = null
        this.bellHighlightTarget.style.left = null
      }
    }

    if (!isMobile()) {
      // Ajust the svg element view box, height and width
      this.svgElementButtonTargets.forEach((svgElement) => {
        const svgHeight = this.svgHeightXL - scrollPercent * (this.svgHeightXL - this.endSvgHeight)
        svgElement.setAttribute("viewBox", `0 0 32 ${svgHeight}`)
        svgElement.setAttribute("height", `${svgHeight}`)
        svgElement.setAttribute("width", `${svgHeight}`)
      })
      // Ajust the buttons container height and width
      this.buttonTargets.forEach((button) => {
        button.style.height = this.buttonHeightXL - scrollPercent * (this.buttonHeightXL - this.endButtonHeight) + "px"
        button.style.width = this.buttonHeightXL - scrollPercent * (this.buttonHeightXL - this.endButtonHeight) + "px"
      })
    }
  }

  checkScreenWidth() {
    if (isMobile()) {
      this.headerHeightXL = 72
      this.logoHeight = 28
    } else {
      this.headerHeightXL = 88
      this.svgHeightXL = 32
      this.buttonHeightXL = 52
      this.logoHeight = 64
    }
  }
}

export { Header }
