import { Controller } from "@hotwired/stimulus"

class ShowPassword extends Controller {
  static targets = ["show", "hide"]
  static classHidden = "-hidden"

  getInput() {
    return this.element.closest(".formfield").querySelector("input")
  }

  show() {
    this.getInput().type = "text"
    this.showTarget.classList.add(ShowPassword.classHidden)
    this.hideTarget.classList.remove(ShowPassword.classHidden)
  }

  hide() {
    this.getInput().type = "password"
    this.showTarget.classList.remove(ShowPassword.classHidden)
    this.hideTarget.classList.add(ShowPassword.classHidden)
  }
}

export { ShowPassword }
