import { Controller } from "@hotwired/stimulus"

const HIDDEN_CLASS = "-hidden"
const EXPANDED_CLASS = "-expanded"
const ANIMATION_CLASS = "-animated"

class BriefItems extends Controller {
  static targets = ["group", "items", "animated"]

  connect() {
    this.hide()
  }

  toggle(event) {
    event.stopPropagation()
    if (this.hidden) {
      this.expand()
    } else {
      this.hide()
    }
  }

  hide() {
    if (!this.hasItemsTarget) return
    if (!this.hidden) {
      this.itemsTarget.classList.add(HIDDEN_CLASS)
      this.groupTarget.classList.remove(EXPANDED_CLASS)
      this.animatedTarget.classList.remove(ANIMATION_CLASS)
      this.hidden = true
    }
  }

  expand() {
    if (!this.itemsTarget) return
    if (this.hidden) {
      this.itemsTarget.classList.remove(HIDDEN_CLASS)
      this.groupTarget.classList.add(EXPANDED_CLASS)
      this.animatedTarget.classList.add(ANIMATION_CLASS)
      this.hidden = false
    }
  }
}

export { BriefItems }
