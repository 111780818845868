import { Controller } from "@hotwired/stimulus"
import * as htmx from "htmx.org"

class Watches extends Controller {
  static targets = ["watch"]
  static attributeObject = "data-watches-object"
  static classHtmxAdded = "htmx-added"
  static classWatched = "-watched"

  watchTargetConnected(elt) {
    if (!elt.classList.contains(Watches.classHtmxAdded)) {
      return
    }

    const watchObject = elt.getAttribute(Watches.attributeObject)
    this.element.querySelectorAll("[" + Watches.attributeObject + "=" + watchObject + "]").forEach((watchElement) => {
      if (watchElement === elt) {
        return
      }

      const newElement = watchElement.cloneNode(true)
      newElement.removeAttribute("hx-post")
      newElement.removeAttribute("hx-delete")
      if (watchElement.getAttribute("hx-post")) {
        newElement.setAttribute(
          "hx-delete", watchElement.getAttribute("hx-post"),
        )
        newElement.classList.add(Watches.classWatched)
      } else {
        newElement.setAttribute(
          "hx-post", watchElement.getAttribute("hx-delete"),
        )
        newElement.classList.remove(Watches.classWatched)
      }
      watchElement.replaceWith(newElement)
      htmx.process(newElement)
    })
  }
}

export { Watches }
