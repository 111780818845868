import { Controller } from "@hotwired/stimulus"

class SmsResend extends Controller {
  static targets = ["countdown", "confirmation", "resendAgain"]

  _countDown() {
    const counter = parseInt(this.countdownTarget.textContent) - 1
    this.countdownTarget.textContent = counter
    if (counter > 0) {
      setTimeout(() => {
        this._countDown()
      }, 1000)
    } else {
      this.confirmationTarget.classList.add("-hidden")
      this.resendAgainTarget.classList.remove("-hidden")
    }
  }

  connect() {
    setTimeout(() => {
      this._countDown()
    }, 1000)
  }
}

export { SmsResend }
