import { Controller } from "@hotwired/stimulus"
import { useDebounce } from "stimulus-use"

class Briefings extends Controller {
  connect() {
    if (!this.element.querySelector(location.hash)) {
      location.hash = location.hash.split("--")[0]
    }
  }
}

class Briefing extends Controller {
  static targets = ["section", "visibleSection"]
  static intersectionRootMargin = "-80px 0px -200px 0px"
  intersectionObserver = null

  connect() {
    if (this.hasSectionTarget) {
      this.intersectionObserver = new IntersectionObserver(
        (entries) => entries.forEach((entry) => this.intersect(entry)),
        { rootMargin: Briefing.intersectionRootMargin },
      )
      this.sectionTargets.forEach((section) =>
        this.intersectionObserver.observe(section),
      )
    }
  }

  intersect(entry) {
    if (entry.isIntersecting) {
      entry.target.dataset.briefingTarget = "section visibleSection"
    } else {
      entry.target.dataset.briefingTarget = "section"
    }
    this.dispatch("section-change")
  }
}

class BriefingToc extends Controller {
  static targets = []
  static outlets = ["briefing"]
  static throttles = ["refreshToc"]
  static classCurrent = "-current"

  connect() {
    // By using a debounce, we keep the latest `section-change` event in a timeframe of 10ms.
    // This avoids refreshing the toc too often while providing fast feedback.
    useDebounce(this, { wait: 10 })
  }

  briefingOutletConnected(outlet, element) {
    setTimeout(() => this.refreshToc(), 100)
    element.addEventListener("briefing:section-change", () => this.refreshToc())
  }

  resetToc() {
    this.element
      .querySelectorAll(`.${BriefingToc.classCurrent}`)
      .forEach((element) => {
        element.classList.remove(BriefingToc.classCurrent)
        element.removeAttribute("aria-current")
        element.parentElement.removeAttribute("aria-expanded")
      })
  }

  refreshToc() {
    if (this.hasBriefingOutlet) {
      for (const briefing of this.briefingOutlets) {
        if (briefing.hasVisibleSectionTarget) {
          const firstVisibleSection = briefing.visibleSectionTarget
          const matchingSelector = `a[href="#${CSS.escape(
            firstVisibleSection.id,
          )}"]`
          const matchingTocLink = this.element.querySelector(matchingSelector)

          this.resetToc()
          matchingTocLink.classList.add(BriefingToc.classCurrent)
          matchingTocLink.parentElement.classList.add(BriefingToc.classCurrent)
          matchingTocLink.setAttribute("aria-current", "true")
          matchingTocLink.parentElement.setAttribute("aria-expanded", "true")
          history.replaceState(null, "", location.pathname + "#" + matchingTocLink.getAttribute("href").slice(1))

          break
        }
      }
    }
  }

  goToAnchor(event) {
    event.preventDefault()

    const anchorName = event.target.getAttribute("href").slice(1)
    const anchor = document.getElementById(anchorName) || document.body

    anchor.scrollIntoView({ behavior: "smooth", block: "start" })
    history.replaceState(null, "", location.pathname + "#" + anchorName)
  }
}

class BriefingHeader extends Controller {
  static targets = ["editionName"]
  static outlets = ["briefing"]
  static throttles = ["refreshHeader", "toggleVisibility"]
  static values = {
    isSticky: Boolean,
  }

  connect() {
    // By using a debounce, we keep the latest `section-change` event in a timeframe of 10ms.
    // This avoids refreshing the header too often while providing fast feedback.
    useDebounce(this, { wait: 10 })
  }

  briefingOutletConnected(outlet, element) {
    setTimeout(() => this.refreshHeader(), 100)
    element.addEventListener("briefing:section-change", () => this.refreshHeader())
    if (this.isStickyValue) {
      this.toggleVisibility()
      window.addEventListener("scroll", () => this.toggleVisibility())
    }
  }

  toggleVisibility() {
    this.element.classList.toggle("-hidden", window.scrollY < 10)
  }

  refreshHeader() {
    if (this.hasBriefingOutlet) {
      for (const briefing of this.briefingOutlets) {
        if (briefing.hasVisibleSectionTarget) {
          const firstVisibleSection = briefing.visibleSectionTarget
          const matchingSelector = `a[href="#${CSS.escape(
            firstVisibleSection.id.split("--")[0],
          )}"]`
          const matchingTocLink = this.element.querySelector(matchingSelector)
          this.editionNameTarget.textContent = matchingTocLink.textContent

          break
        }
      }
    }
  }

  goToAnchor(event) {
    event.preventDefault()

    const anchorName = event.target.getAttribute("href").slice(1)
    const anchor = document.getElementById(anchorName) || document.body

    anchor.scrollIntoView({ behavior: "smooth", block: "start" })
  }
}

class BriefingDatepicker extends Controller {
  showPicker(event) {
    try {
      event.target.showPicker()
    } catch { }
  }

  updateDate(event) {
    event.target.form.submit()
  }
}

export { Briefings, Briefing, BriefingHeader, BriefingToc, BriefingDatepicker }
