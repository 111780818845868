import { Controller } from "@hotwired/stimulus"

class Dialog extends Controller {
  static targets = ["modal", "button"]
  static classHidden = "-hidden"
  static values = {
    open: Boolean,
  }

  initialize() {
    if (typeof HTMLDialogElement !== "function") {
      this.element.classList.add(Dialog.classHidden)
    }
    if (this.openValue) this.showModal()
  }

  showModal() {
    this.modalTarget.showModal()
  }

  cancelIfClickOutside(event) {
    const rect = this.modalTarget.getBoundingClientRect()
    const isInDialog = (
      rect.top <= event.clientY &&
      event.clientY <= rect.top + rect.height &&
      rect.left <= event.clientX &&
      event.clientX <= rect.left + rect.width
    )
    if (event.target === this.modalTarget && !isInDialog) {
      this.cancel()
    }
    event.stopPropagation()
  }

  close() {
    this.modalTarget.close()
  }

  cancel() {
    this.dispatch("cancel")
    this.close()
  }
}

export { Dialog }
