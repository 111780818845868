import { paramCase } from "param-case"
import { log } from "../utils/logging"

// Whitelist of event properties
const EVENT_PROPERTIES = [
  "eventCategory",
  "objectId",
  "objectName",
  "pageTitle",
  "pageType",
  "pageGeo",
  "searchQuery",
  "searchCount",
  "$feature_flag",
  "$feature_flag_response",
]

// Whitelist of user properties
const USER_PROPERTIES = [
  "userId",
  "userSectors",
  "userType",
]

export class PostHog {
  isEnabled = false
  eventQueue = []

  // --- Public methods

  init(token) {
    if (!token || this.isInit()) {
      return
    }

    // Load script
    /* eslint-disable */
    !(function(t, e) { let o, n, p, r; e.__SV || (window.posthog = e, e._i = [], e.init = function(i, s, a) { function g(t, e) { const o = e.split('.'); o.length == 2 && (t = t[o[0]], e = o[1]), t[e] = function() { t.push([e].concat(Array.prototype.slice.call(arguments, 0))) } } (p = t.createElement('script')).type = 'text/javascript', p.async = !0, p.src = s.api_host + '/static/array.js', (r = t.getElementsByTagName('script')[0]).parentNode.insertBefore(p, r); let u = e; for (void 0 !== a ? u = e[a] = [] : a = 'posthog', u.people = u.people || [], u.toString = function(t) { let e = 'posthog'; return a !== 'posthog' && (e += '.' + a), t || (e += ' (stub)'), e }, u.people.toString = function() { return u.toString(1) + '.people (stub)' }, o = 'capture identify alias people.set people.set_once set_config register register_once unregister opt_out_capturing has_opted_out_capturing opt_in_capturing reset isFeatureEnabled onFeatureFlags'.split(' '), n = 0; n < o.length; n++)g(u, o[n]); e._i.push([i, s, a]) }, e.__SV = 1) }(document, window.posthog || []))

    // Posthog settings https://posthog.com/docs/integrate/client/js
    window.posthog = window.posthog || []
    window.posthog.init(token, {
      api_host: 'https://eu.posthog.com',
      // Disable autocapture
      autocapture: false,
      // Disable capture pageview
      capture_pageview: false,
      // Enable advanced features in order to perform the A/B tests
      advanced_disable_decide: false,
      // Backup the posthog data of the user locally
      persistence: 'localStorage',
      loaded: () => {
        log('PostHog init OK')
      }
    })

    return true
  }

  isInit() {
    return !!window.posthog
  }

  // Enable tracking
  enableTracking(enabled) {
    if (this.isInit()) {
      this.isEnabled = enabled
      if (enabled) {
        this.sendEventQueue()
      }
    }
  }

  // Send Pageview to PostHog
  trackPageView(params) {
    const props = this.buildProperties(params)
    this.handleEvent('$pageview', props)
  }

  // Build event properties and handle it
  trackEvent(eventCategory, eventName, params) {
    params = { ...params, eventCategory }
    const props = this.buildProperties(params)
    this.handleEvent(eventName, props)
  }

  // --- Private methods

  // Send Event or queue it
  handleEvent(eventName, props) {
    if (this.isEnabled) {
      this.sendEvent(eventName, props)
    } else {
      log('PostHog queueEvent', eventName, props)
      this.eventQueue.push({ eventName, props })
    }
  }

  // Send queued Events to Posthog and clear queue
  sendEventQueue() {
    this.eventQueue.forEach(({ eventName, props }) => {
      this.sendEvent(eventName, props)
    })
    this.eventQueue = []
  }

  // Send Event to Posthog
  sendEvent(eventName, props) {
    log('PostHog trackEvent', eventName, props)
    if (props.$set.user_id) {
      window.posthog.identify(props.$set.user_id)
    }
    window.posthog.capture(eventName, props)
  }

  snakeCase(text) {
    return paramCase(text, { delimiter: '_', stripRegexp: /[^A-Z0-9$]+/gi })
  }

  buildProperties(params) {
    const props = EVENT_PROPERTIES.reduce((memo, prop) => {
      if (params[prop]) {
        memo[this.snakeCase(prop)] = params[prop]
      }
      return memo
    }, {})

    props.$set = USER_PROPERTIES.reduce((memo, prop) => {
      if (params[prop]) {
        memo[this.snakeCase(prop)] = params[prop]
      }
      return memo
    }, {})

    return props
  }
}
