import { Controller } from "@hotwired/stimulus"

class ChipField extends Controller {
  toggleCheckbox() {
    const checkbox = this.element.querySelector("input[type=checkbox]")
    checkbox.checked = !checkbox.checked
  }
}

export { ChipField }
