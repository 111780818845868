import { Controller } from "@hotwired/stimulus"

class Menu extends Controller {
  static targets = ["edition"]
  static outlets = ["header"]
  static classHidden = "-hidden"
  static values = {
    isHuge: String,
  }

  editionSlugs = []

  connect() {
    this.handleDropsDownPersistence()
  }

  open(currentScrollPercent) {
    if (this.element.showModal) {
      this.element.showModal()
    }
    this.element.classList.remove(Menu.classHidden)
    this.refreshDependencies()
    this.element.style.marginTop = this.headerHeightXL - currentScrollPercent * (this.headerHeightXL - this.endHeaderHeight) + "px"
  }

  close() {
    if (this.element.close) {
      this.element.close()
    }
    this.element.classList.add(Menu.classHidden)
    this.refreshDependencies()
    if (this.hasHeaderOutlet) {
      this.headerOutlet.buttonTarget.focus()
    }
  }

  modalClose(event) {
    if (!this.isOpen()) {
      return
    }

    if (event.clientX > this.element.offsetWidth) {
      this.close()
    }
  }

  isOpen() {
    return !this.element.classList.contains(Menu.classHidden)
  }

  setUserPreferences(event) {
    const editionSlug = event.currentTarget.dataset.menuEditionSlugValue

    if (this.editionSlugs.includes(editionSlug)) {
      this.editionSlugs = this.editionSlugs.filter(slug => slug !== editionSlug)
    } else {
      this.editionSlugs.push(editionSlug)
    }

    localStorage.setItem("selectedEditions", JSON.stringify(this.editionSlugs))
  }

  refreshDependencies() {
    if (this.hasHeaderOutlet) {
      this.headerOutlet.refreshIconStatus()
    }
  }

  // Manages dropdown persistence across sessions by checking session existence and updating edition dropdown states.
  // Uses both sessionStorage and localStorage to determine and store the dropdown states.
  handleDropsDownPersistence() {
    const isNewSession = !sessionStorage.getItem("isExistingSession")
    if (isNewSession) {
      sessionStorage.setItem("isExistingSession", "true")
      this.editionSlugs = []
      this.editionTargets.forEach((edition) => {
        if (edition.hasAttribute("open")) {
          this.editionSlugs.push(edition.dataset.menuEditionSlugValue)
        }
      })

      localStorage.setItem("selectedEditions", JSON.stringify(this.editionSlugs))
    } else {
      const selectedEditions = localStorage.getItem("selectedEditions")
      if (selectedEditions) {
        this.editionSlugs = JSON.parse(selectedEditions)
        this.editionTargets.forEach((edition) => {
          const slug = edition.dataset.menuEditionSlugValue
          if (this.editionSlugs.includes(slug)) {
            edition.setAttribute("open", "")
          } else {
            edition.removeAttribute("open")
          }
        })
      }
    }
  }
}

export { Menu }
