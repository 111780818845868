import { Controller } from "@hotwired/stimulus"

class Select extends Controller {
  static targets = ["label"]
  static eventSelected = new Event("click")
  static values = {
    stateless: Boolean,
  }

  connect() {
    if (this.statelessValue) {
      return
    }
    const checkedRadioInput = this.element.querySelector("input:checked")
    checkedRadioInput && this._updateLabel(checkedRadioInput.value)
  }

  _updateLabel(value) {
    if (this.labelTarget.dataset.emptyLabel && !value.length) {
      this.labelTarget.innerText = this.labelTarget.dataset.emptyLabel
    } else {
      this.labelTarget.innerText = value
    }
  }

  updateLabel(e) {
    if (this.statelessValue) {
      return
    }
    this._updateLabel(e.target.value)
  }

  selectOption(e) {
    /* for accessibility purpose */
    const input = e.target.firstElementChild
    input.checked = !input.checked
    this._updateLabel(input.value)
    e.target.dispatchEvent(Select.eventSelected)
  }
}

export { Select }
