import { Controller } from "@hotwired/stimulus"

class BackButton extends Controller {
  static targets = ["button", "label", "stage"]
  static hiddenClass = "-hidden"

  connect() {
    if (this.buttonTarget.classList.contains(BackButton.hiddenClass) && this.element.dataset.isEditorialLocked !== "True") {
      this.buttonTarget.classList.remove(BackButton.hiddenClass)
      this.buttonTarget.dataset.defaultLabel = this.labelTarget.textContent
      this.moveToLeftColumn()
    }

    this.refresh()
  }

  moveToLeftColumn() {
    // TODO: explicitly set stage target in templates
    const firstStage = this.hasStageTarget ? this.stageTarget : this.element.querySelector(".layout-stage")

    if (!firstStage) {
      console.error(
        "There is no div with a 'layout-stage' in the panel to inject this back button:",
        this.element,
      )
      return
    }

    let leftColumn = firstStage.querySelector(".left-column")

    if (!leftColumn) {
      leftColumn = document.createElement("div")
      leftColumn.classList.add("left-column")
      firstStage.prepend(leftColumn)
    }

    leftColumn.prepend(this.buttonTarget)
  }

  refresh() {
    const allPanels = [...this.element.parentElement.children]
    const ownPanelIndex = allPanels.indexOf(this.element)

    let label = this.buttonTarget.dataset.defaultLabel

    if (allPanels.length > 1 && ownPanelIndex > 0) {
      const previousPanel = allPanels[ownPanelIndex - 1]

      if (previousPanel.dataset.backLabel) {
        label = previousPanel.dataset.backLabel
      }
    }

    this.labelTarget.textContent = label
  }
}

export { BackButton }
