import { Controller } from "@hotwired/stimulus"

class Dropdown extends Controller {
  static targets = ["cta"]
  static classOpen = "-open"

  connect() {
    document.addEventListener("click", (evt) => {
      if (
        this.element.classList.contains(Dropdown.classOpen) &&
        evt.target.closest("[data-controller~=dropdown]") !== this.element
      ) {
        this.close()
      }
    })
    document.addEventListener("keyup", (evt) => {
      if (this.element.classList.contains(Dropdown.classOpen) && evt.key === "Escape") {
        this.close()
      }
    })
  }

  open() {
    this.element.classList.add(Dropdown.classOpen)
  }

  close() {
    this.element.classList.remove(Dropdown.classOpen)
  }

  toggle() {
    this.element.classList.toggle(Dropdown.classOpen)
  }

  rotate() {
    this.ctaTarget.classList.toggle(Dropdown.classOpen)
  }
}

export { Dropdown }
