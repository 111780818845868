import { Controller } from "@hotwired/stimulus"

class Confirm extends Controller {
  static targets = ["button", "modal"]
  static eventConfirmed = new Event("confirmed")
  static classOpen = "-open"

  open() {
    this.modalTarget.classList.add(Confirm.classOpen)
  }

  confirm() {
    this.close()
    this.buttonTarget.dispatchEvent(Confirm.eventConfirmed)
  }

  close() {
    this.modalTarget.classList.remove(Confirm.classOpen)
  }
}

export { Confirm }
