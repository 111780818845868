import { Controller } from "@hotwired/stimulus"

class Ticker extends Controller {
  static values = {
    briefitems: String,
  }

  connect() {
    const briefitems = JSON.parse(this.briefitemsValue)
    this.briefitems = briefitems
    this.index = 0
    this.briefitem = briefitems[this.index]
    setInterval(() => this.loop(), 5000)
  }

  loop() {
    const { briefitem, briefitems } = this
    this.index++
    const nextBriefitem = briefitems[this.index % briefitems.length]
    this.briefitem = nextBriefitem

    if (this.shouldAnimateBriefing(briefitem, nextBriefitem)) {
      const briefingContainer = this.getBriefingContainer()
      const briefingNode = briefingContainer.firstElementChild
      const nextBriefingNode = this.buildBriefingNode(nextBriefitem)
      briefingContainer.appendChild(nextBriefingNode)

      this.slideUpNode(briefingNode)
    } else if (this.shouldAnimateKicker(briefitem, nextBriefitem)) {
      const kickerContainer = this.getKickerContainer()
      const kickerNode = kickerContainer.firstElementChild
      const nextKickerNode = this.buildKickerNode(nextBriefitem)
      kickerContainer.appendChild(nextKickerNode)

      this.slideUpNode(kickerNode)
    } else if (this.shouldAnimateBriefitem(briefitem, nextBriefitem)) {
      const briefitemContainer = this.getBriefitemContainer()
      const briefitemNode = briefitemContainer.firstElementChild
      const nextBriefitemNode = this.buildBriefitemNode(nextBriefitem)
      briefitemContainer.appendChild(nextBriefitemNode)

      this.slideUpNode(briefitemNode)
    }
  }

  shouldAnimateBriefing(briefitem, nextBriefitem) {
    return briefitem.briefing.edition.name !== nextBriefitem.briefing.edition.name
  }

  shouldAnimateKicker(briefitem, nextBriefitem) {
    return briefitem.kicker !== nextBriefitem.kicker
  }

  shouldAnimateBriefitem(briefitem, nextBriefitem) {
    return briefitem.title !== nextBriefitem.title
  }

  getBriefingContainer(parent) {
    return (parent || this.element).querySelector(".ticker__briefing-container")
  }

  getKickerContainer(parent) {
    return (parent || this.element).querySelector(".ticker__kicker-container")
  }

  getBriefitemContainer(parent) {
    return (parent || this.element).querySelector(".ticker__briefitem-container")
  }

  hideNode(node) {
    node.classList.add("ticker__hidden")
  }

  showNode(node) {
    node.classList.remove("ticker__hidden")
  }

  slideUpNode(node, callback) {
    node.addEventListener("animationend", event => {
      event.stopPropagation()
      node.remove()
      callback && callback()
    })
    node.classList.add("ticker__slide-up")
  }

  buildBriefingNode(briefitem, raw) {
    const kickerNode = this.buildKickerNode(briefitem, true)
    return this.buildNode(`
      <div class="ticker__briefing-box ticker__briefing">
        <a href="${briefitem.briefing.url}" class="ticker__briefing-edition font-${briefitem.briefing.edition.slug}" tabindex="-1">
          ${briefitem.briefing.edition.name}
        </a>
        <div class="ticker__kicker-container">
          ${kickerNode}
        </div>
      </div>
    `, raw)
  }

  buildKickerNode(briefitem, raw) {
    const briefitemNode = this.buildBriefitemNode(briefitem, true)
    const tickerNode = briefitem.kicker
      ? `
        <a href="${briefitem.last_kicker_url}" class="ticker__kicker" tabindex="-1">
          <div class="ticker__kicker-title">${briefitem.kicker}</div>
        </a>
    `
      : ""

    return this.buildNode(`
      <div class="ticker__kicker-box">
        ${tickerNode}
        <div class="ticker__briefitem-container">
          ${briefitemNode}
        </div>
      </div>
    `, raw)
  }

  buildBriefitemNode(briefitem, raw) {
    return this.buildNode(`
      <a href="${briefitem.url}" class="ticker__briefitem-box ticker__briefitem-content" tabindex="-1">
        <span class="ticker__briefitem-geo">${briefitem.geography}</span>
        <span class="ticker__briefitem-title">${briefitem.title}</span>
      </a>
    `, raw)
  }

  buildNode(html, raw = false) {
    // If raw, return the HTML string as is.
    if (raw) return html

    // Trim spaces
    html = html.trim()

    // Set up a new template element.
    const template = document.createElement("template")
    template.innerHTML = html
    const result = template.content.children

    // Return either an HTMLElement or HTMLCollection,
    return result.length === 1 ? result[0] : result
  }
}

export { Ticker }
