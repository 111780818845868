import { PaywallBase } from "../base/paywall.js"

class PaywallDetails extends PaywallBase {
  static targets = [...PaywallBase.targets, "section"]

  setHeight() {
    let topOffset = this.sectionTarget.getBoundingClientRect().bottom
    topOffset = this.sectionTarget.classList.contains("briefitem-body") ? topOffset * 0.9 : topOffset
    this.containerTarget.style.marginTop = `${Math.min(
      topOffset,
      window.visualViewport.height - (this.containerTarget.offsetHeight / 4),
    )}px`
  }

  connect() {
    super.connect()
    this.setHeight()
  }
}

export { PaywallDetails }
